.app-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  color: #333;
}

.input-container {
  margin-bottom: 20px;
}

input {
  width: 70%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  margin-left: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.task-list {
  list-style: none;
  padding: 0;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.task-actions button {
  margin-left: 5px;
  background-color: #dc3545;
}

.task-actions button:first-child {
  background-color: #ffc107;
}

.task-actions button:hover {
  background-color: #c82333;
}

.time {
  font-size: 12px;
  color: #6c757d;
}

.last-action {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}
